// Anpassungen für den Internet Explorer 7
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


@import "variables";


// ***** Globale Einstellungen *************************************************
small {
	font-size: 12px;
}

a {
	&.external {
		background-image: url(../../Public/Images/StyleSheets/ie7-link-external.png);
	}

	&.internal {
		background-image: url(../../Public/Images/StyleSheets/ie7-link-internal.png);
	}

	&.more {
		@extend .arrow-small-cyan-right;
	}

	&.more-white {
		@extend .arrow-small-white-right;
	}
}



// ***** Pfeile ****************************************************************
@each $color in brown, green, grey, orange, purple, red {
	.arrow-big-#{$color}-right {
		background-image: url(../../Public/Images/StyleSheets/ie7-arrow-big-#{$color}-right.png);
	}
}

@each $color in cyan, white {
	.arrow-medium-#{$color}-right {
		background-image: url(../../Public/Images/StyleSheets/ie7-arrow-medium-#{$color}-right.png);
	}
}

.arrow-medium-white-down {
	background-image: url(../../Public/Images/StyleSheets/ie7-arrow-medium-white-down.png);
}

.arrow-medium-white-up {
	background-image: url(../../Public/Images/StyleSheets/ie7-arrow-medium-white-up.png);
}

@each $color in black, cyan, white {
	.arrow-small-#{$color}-left {
		background-image: url(../../Public/Images/StyleSheets/ie7-arrow-small-#{$color}-left.png);
	}
}

@each $color in black, cyan, grey, lightcyan, white {
	.arrow-small-#{$color}-right {
		background-image: url(../../Public/Images/StyleSheets/ie7-arrow-small-#{$color}-right.png);
	}
}

.triangle-down-cyan {
	background-image: url(../../Public/Images/StyleSheets/ie7-triangle-down-cyan.png);
}



// ***** Meta-Navigation *******************************************************
.nav-meta {
	list-style: none;
	margin-top: -1px;
}

.nav-meta-item {
	display: inline;
}

.nav-meta-search-field {
	padding-top: 6px;
}

.nav-meta-search-submit {
	background: none !important;
}


// ***** Hauptnavigation *******************************************************
.nav-main {
	background-image: url(../../Public/Images/StyleSheets/ie7-nav-main-background.gif);
}

.nav-main-item {
	display: block;
	float: left;
    zoom: 1;

	&:after {
		clear: both;
	}
}

.nav-main-level2 {
	margin-top: 32px;
}

.nav-main-level2-link {
	&:hover, &:active, &:focus {
		@extend .arrow-small-black-right;
	}
}



// ***** Unternavigation *******************************************************
.nav-sub-link {
	&:hover, &:active, &:focus {
		@extend .arrow-small-cyan-right;
	}
}



// ***** Seitenfunktionen ******************************************************
.page-functions {
    zoom: 1;

	&:after {
		clear: both;
	}
}

.page-functions-item {
	display: block;
	float: left;
}

.page-functions-link {
	display: block;
}

@each $item in print, pdf, recommend, rss {
	.page-functions-link-#{$item} {
		background-image: url(../../Public/Images/StyleSheets/ie7-pagefunctions-#{$item}.png);
	}
}



// ***** Social-Bookmarks ******************************************************
.social-bookmarks {
    zoom: 1;

	&:after {
		clear: both;
	}
}

.social-bookmarks-item {
	display: block;
	float: left;
}

.social-bookmarks-link {
	display: block;
}

.social-bookmarks-link-delicious {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-delicious.gif);
}

.social-bookmarks-link-digg {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-digg.gif);
}

.social-bookmarks-link-facebook {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-facebook.gif);
}

.social-bookmarks-link-google {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-google.gif);
}

.social-bookmarks-link-misterwong {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-misterwong.gif);
}

.social-bookmarks-link-stumbleupon {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-stumpleupon.gif);
}

.social-bookmarks-link-twitter {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-bookmarks-twitter.gif);
}



// ***** Verwandte Themen ******************************************************
.related-topics-header {
	background-image: url(../../Public/Images/StyleSheets/ie7-related-topics-header.png);
}

.related-topics-items {
	@each $color in brown, green, grey, orange, purple, red {
		.colorscheme-#{$color} & {
			background-image: url(../../Public/Images/StyleSheets/ie7-triangle-down-#{$color}.png);
		}
	}
}

.related-topics-item {
	display: inline;
}


// ***** Footer-Sitemap ********************************************************
.footer-sitemap-header {
	padding-top: 5px;
}

.footer-sitemap-menuitem {
	&:hover, &:active, &:focus {
		@extend .arrow-small-white-right;
	}
}



// ***** Listen ****************************************************************
.bullet-list {
	li {
		background-image: url(../../Public/Images/StyleSheets/ie7-bulletlist.gif);
	}
}



// ***** Aufmacher Startseite **************************************************
.leadstory-link {
	&:hover, &:active, &:focus {
		@each $color in brown, green, grey, orange, purple, red {
			.leadstory-color-#{$color} {
				.leadstory-header {
					@extend .arrow-big-#{$color}-right;
				}
			}
		}
	}
}



// ***** Teaser Kapiteleinstiegsseite ******************************************
.entryteaser-link {
	&:hover, &:active, &:focus {
		@each $color in brown, green, grey, orange, purple, red {
			.entryteaser-color-#{$color} {
				@extend .arrow-big-#{$color}-right;
			}
		}
	}
}



// ***** Teaser Doorpage *******************************************************
.doorteaser-link {
	&:hover, &:active, &:focus {
		.doorteaser-header {
			@each $color in brown, green, grey, orange, purple, red {
				.colorscheme-#{$color} & {
					@extend .arrow-big-#{$color}-right;
				}
			}
		}
	}
}

.doorteaser-text {
	color: $textColor;
}



// ***** Themenbox *************************************************************
@each $icon in contact, downloads, events, forms, links, mapmarker, news, phone, services {
	.topicbox-header-#{$icon} {
		background-image: url(../../Public/Images/StyleSheets/ie7-topicbox-#{$icon}.png);
	}
}

.topicbox-header-inner {
	.js & {
		@extend .arrow-medium-white-down;
		background-position: right center;
	}
}

.topicbox-header-inner-open {
	.js & {
		@extend .arrow-medium-white-up;
		background-position: right center;
	}
}



// ***** Vier Boxen auf Startseite *********************************************
.home-fourboxes-col-top {
	:first-child {
		// Die Überschrift ist hier oben angeschnitten
		margin-top: 2px;
	}
}



// ***** Social-Content ********************************************************
.social-content-dotted-line {
	background-image: url(../../Public/Images/StyleSheets/ie7-social-content-dotted-line.gif);
}

.social-content-content {
	zoom: 1;
}

.social-content-link-facebook {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-facebook.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-facebook-active.png);
	}
}

.social-content-link-pinterest {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-pinterest.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-pinterest-active.png);
	}
}

.social-content-link-rss {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-rss.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-rss-active.png);
	}
}

.social-content-link-twitter {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-twitter.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-twitter-active.png);
	}
}

.social-content-link-youtube {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-youtube.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-youtube-active.png);
	}
}

.social-content-link-xing {
    background-image: url(../../Public/Images/StyleSheets/ie7-social-content-xing.png);

	&.social-content-link-active,
	&:hover, &:active, &:focus {
        background-image: url(../../Public/Images/StyleSheets/ie7-social-content-xing-active.png);
	}
}



// ***** Linkliste *************************************************************
.link-list-item {
	@extend .arrow-small-cyan-right;
}



// ***** Pagebrowser ***********************************************************
.pagebrowser-prev {
	a:hover &, a:active &, a:focus & {
		@extend .arrow-small-black-left;
	}
}

.pagebrowser-next {
	a:hover &, a:active &, a:focus & {
		@extend .arrow-small-black-right;
	}
}



// ***** News ******************************************************************
.news-list-more {
	a {
		@extend a, .more;
	}
}



// ***** Veranstaltungen *******************************************************
.events-calendar-nav-link {
	padding-bottom: 3px;
}

.events-calender-nav {
	background: #5ac5f2;
}

.events-filter {
	background-image: url(../../Public/Images/StyleSheets/ie7-triangle-down-darkgrey.png);
}

.events-margin-header {
	@extend .topicbox-header-events;
}

.events-margin-header-dark {
	background-image: url(../../Public/Images/StyleSheets/ie7-calender-filter.png) !important;
}

.events-submit {
	@extend .arrow-small-white-right;
}



// ***** Formulare *************************************************************
.form-submit {
	@extend .arrow-small-cyan-right;
}



// ***** Suche *****************************************************************
.search-result-header {
	margin-top: 20px;
}

.search-result-header-title,
.search-result-header-rating {
	padding-top: 3px;
}

.search-margin-submit {
	padding-top: 2px;
	@extend .arrow-small-white-right;
	background-position: left 2px;
}



// ***** Clearfix-Ergänzung ****************************************************
.cf {
    zoom: 1;
}
