// Variablen
//
// @author Chris Müller <mueller@cyperfection.de>
// @version $Id$
// @package ludwigshafen


// ***** Farben ****************************************************************

$textColor: #58595b;

$cyan: #007bc1;
$lightcyan: #4cbbea;
$lightgrey: #e2e2e2;

$errorColor: #e40045;


// Farben für Content-Header, Aufmacher Startseite, Teaser Kapiteleinstiegsseite, Teaser Doorpage
$barColorBrown:  #944e29;
$barColorGreen:  #31b54e;
$barColorGrey:   #58595b;
$barColorOrange: #ee8b2d;
$barColorPurple: #9e028e;
$barColorRed:    #e40045;

// Verlauf für Aufmacher Startseite und Teaser Kapiteleinstiegsseite
// Achtung: Die untenstehenden Farben sind auch für den IE9 als SVG definiert!
// @see all_ie9.scss
$leadstoryGradientTopBrown:     #4e1d00;
$leadstoryGradientBottomBrown:  #e29e76;
$leadstoryGradientTopGreen:     #007739;
$leadstoryGradientBottomGreen:  #6bdc84;
$leadstoryGradientTopGrey:      #030303;
$leadstoryGradientBottomGrey:   #b1b0b0;
$leadstoryGradientTopOrange:    #b04a00;
$leadstoryGradientBottomOrange: #f6ba2b;
$leadstoryGradientTopPurple:    #59015e;
$leadstoryGradientBottomPurple: #ea7cd3;
$leadstoryGradientTopRed:       #78001b;
$leadstoryGradientBottomRed:    #ee7487;

// Verlauf für Teaser Doorpage
$doorteaserGradientTopRight:   #dfdfe1;
$doorteaserGradientBottomLeft: #f5f5f6;

$doorteaserGradientTopRightBrown:    #fdf6f3;
$doorteaserGradientBottomLeftBrown:  #ebe2dd;
$doorteaserGradientTopRightGreen:    #f3fff5;
$doorteaserGradientBottomLeftGreen:  #d9efe3;
$doorteaserGradientTopRightGrey:     #f2f3f3;
$doorteaserGradientBottomLeftGrey:   #d9d9d9;
$doorteaserGradientTopRightOrange:   #fff8e8;
$doorteaserGradientBottomLeftOrange: #fce9db;
$doorteaserGradientTopRightPurple:   #fcebf8;
$doorteaserGradientBottomLeftPurple: #f1d9ee;
$doorteaserGradientTopRightRed:      #fdf1f3;
$doorteaserGradientBottomLeftRed:    #f7d9e0;


// Farben für Verwandte Themen
$relatedColorBrown:  #763d1b;
$relatedColorGreen:  #009245;
$relatedColorGrey:   #333;
$relatedColorOrange: #eb690b;
$relatedColorPurple: #7b0a82;
$relatedColorRed:    #c6002d;

$relatedGradientTopBrown:     #b25131;
$relatedGradientBottomBrown:  $relatedColorBrown;
$relatedGradientTopGreen:     #61be61;
$relatedGradientBottomGreen:  $relatedColorGreen;
$relatedGradientTopGrey:      #7b7b7b;
$relatedGradientBottomGrey:   #404040;
$relatedGradientTopOrange:    #ffa34b;
$relatedGradientBottomOrange: $relatedColorOrange;
$relatedGradientTopPurple:    #b3409b;
$relatedGradientBottomPurple: $relatedColorPurple;
$relatedGradientTopRed:       #e7465f;
$relatedGradientBottomRed:    $relatedColorRed;


// ***** Breiten ***************************************************************

$siteWidth: 960px;

// Breite Hauptinhalt
$mainContentWidth: 430px;

// Breite rechte Marginalspalte
$marginContentWidth: 400px;
